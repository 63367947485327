import './nav.scss'

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import useHasFeature, {
    AppFeatures,
    AppSubFeatures,
    useGetFeatureConfig,
} from 'hooks/useHasFeature'
import useInterval from 'hooks/useInterval'
import { showAgents as toggleAgents } from 'store/agents/agents.actions'
import { getUnreadScoresCount } from 'store/qa/qa.actions'
import RootState from 'store/state'

import NavElement, { LinkProps } from './NavElement/NavElement'

import ChatOutline from 'images/nav-chat-outline.svg'
import MetricsFill from 'images/nav-metrics-fill.svg'
import MetricsOutline from 'images/nav-metrics-outline.svg'
import SettingsFill from 'images/nav-settings-fill.svg'
import SettingsOutline from 'images/nav-settings-outline.svg'
import { ReactComponent as PhoneIcon } from 'images/nav-voice.svg'

import QAFill from 'images/nav-qa-fill.svg'
import QAOutline from 'images/nav-qa-outline.svg'

import EmailOutline from 'images/nav-email-outline.svg'
import EmailFill from 'images/nav-email.svg'

import CampaignManagementOutline from 'images/icon-campaignmanagement-outline.svg'
import CampaignManagementFill from 'images/icon-campaignmanagement.svg'

import TaskMessageFill from 'images/task-message-fill.svg'
import TaskMessageOutline from 'images/task-message-outline.svg'

import { useFocusElement } from 'hooks/useFocusElement'
import AdminSettingsFill from 'images/nav-admin-settings-fill.svg'
import AdminSettingsOutline from 'images/nav-admin-settings-outline.svg'
import { conditionalPluralise } from 'utils'

const Nav: React.FC = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const hasFeature = useHasFeature()
    const quickSightConfig = useGetFeatureConfig()(AppFeatures.QUICKSIGHT)

    const agentsActive = useSelector((state: RootState) => state.agents.agentsActive)
    const features = useSelector((state: RootState) => state.app.features)
    const navPlugins = useSelector((state: RootState) =>
        state.app.plugins.filter((plugin) => plugin.type === 'nav'),
    )

    const hasQAFeature = hasFeature(AppFeatures.QA)
    const unreadScoresCount =
        (hasQAFeature && useSelector((state: RootState) => state.qa.unreadScoresCount)) || 0

    const callGetUnreadScoresCount = () => {
        if (!hasQAFeature) return
        dispatch(getUnreadScoresCount())
    }

    useEffect(() => {
        callGetUnreadScoresCount()
    }, [])

    // poll for unread scores every 30 seconds
    useInterval(async () => {
        callGetUnreadScoresCount()
    }, 30000)

    useEffect(() => {
        if (agentsActive) toggleAgents()
        // eslint-disable-next-line
    }, [location.pathname])

    const connectWebUrl = useSelector<RootState, string>((state) => {
        if (state.app.callProvider === 'kumodi') return ''
        if (state.app.instance!.ccpURL.indexOf('my.connect.aws') !== -1) {
            //new style ccp url
            return /https:\/\/.*?\//.exec(state.app.instance!.ccpURL)![0] + 'home'
        } else {
            return /https:\/\/.*?\/connect/.exec(state.app.instance!.ccpURL)![0] + '/home'
        }
    })

    const getPluginNavLinks = (section: string): LinkProps[] => {
        return navPlugins
            .filter((plugin) => plugin.view === section)
            .map((plugin) => ({
                path: `/${section}/${plugin.name}`,
                title: plugin.title,
                name: plugin.name,
                roles: plugin.roles,
            }))
    }

    const metricsLinks: LinkProps[] = [
        {
            title: 'Real-time data',
            path: '/metrics/real-time',
            name: AppFeatures.REALTIME_METRICS,
        },
        {
            title: 'Contact Search',
            path: '/metrics/historic',
            name: AppFeatures.HISTORIC_METRICS,
            clearContactOnClick: true,
        },
        {
            title: 'Queued Tasks',
            path: '/metrics/queued-tasks',
            name: AppFeatures.QUEUED_TASKS,
        },
        {
            title: 'Automated Rules',
            path: '/metrics/automated-rules',
            name: AppFeatures.AUTOMATED_RULES,
        },
    ]
    metricsLinks.push(...getPluginNavLinks('metrics'))

    const settingLinks: LinkProps[] = [
        {
            title: 'Connect',
            path: connectWebUrl,
            external: true,
            name: AppFeatures.CONNECT,
        },
        {
            title: 'QuickSight',
            path: quickSightConfig?.url,
            external: true,
            name: AppFeatures.QUICKSIGHT,
        },
        {
            title: 'Audio Settings',
            path: '/settings/audio',
            name: AppFeatures.AUDIO_SETTINGS,
        },
        {
            title: 'Upload rota',
            path: '/settings/uploadrota',
            name: AppFeatures.UPLOAD_ROTA,
        },
        {
            title: 'Keyword management',
            path: '/settings/keyword',
            name: AppFeatures.KEYWORD_MANAGEMENT,
        },
        {
            title: 'Directory',
            path: '/settings/directory',
            name: AppFeatures.EXTERNAL_DIRECTORY,
            subfeatures: [AppSubFeatures.DIRECTORY_EDIT],
        },
        {
            title: 'Agent status',
            path: '/settings/agentstatus',
            name: AppFeatures.AGENT_STATUS,
        },
        {
            title: 'Custom Messaging',
            path: '/settings/custom-messaging',
            name: AppFeatures.CUSTOM_MESSAGING,
        },
        {
            title: 'Permissions',
            path: '/settings/permissions',
            name: AppFeatures.PERMISSIONS,
            subfeatures: [AppSubFeatures.MANAGE_PERMISSIONS],
        },
        {
            title: 'Queues',
            path: '/settings/queues',
            name: AppFeatures.QUEUES,
        },
    ]
    settingLinks.push(...getPluginNavLinks('settings'))

    const adminSettingLinks: LinkProps[] = [
        {
            title: 'Announcements',
            path: '/admin-settings/announcements',
            name: AppFeatures.ANNOUNCEMENTS,
            subfeatures: [AppSubFeatures.ANNOUNCEMENTS_PUBLISH],
        },
        {
            title: 'App configuration',
            path: '/admin-settings/app-configuration',
            name: AppFeatures.APP_CONFIGURATION,
        },
        {
            title: 'Audit log',
            path: '/admin-settings/audit-log',
            name: AppFeatures.AUDIT_LOG,
        },
        {
            title: 'Business continuity',
            path: '/admin-settings/lines',
            name: AppFeatures.LINE_MANAGEMENT,
        },
        {
            title: 'Manage Contact Logs',
            path: '/admin-settings/contact-logs',
            name: AppFeatures.CONTACT_LOGS,
        },
        {
            title: 'Users',
            path: '/admin-settings/users',
            name: AppFeatures.USER_MANAGEMENT,
        },
        {
            title: 'Welcome messaging',
            path: '/admin-settings/welcome-messaging',
            name: AppFeatures.WELCOME_MESSAGING,
        },
        {
            title: 'Quick reply management',
            path: '/admin-settings/quick-reply-management',
            name: AppFeatures.QUICK_REPLY_MANAGEMENT,
        },
        {
            title: 'Channel settings',
            path: '/admin-settings/channel-settings',
            name: AppFeatures.CHANNEL_SETTINGS,
            subfeatures: [
                AppSubFeatures.EMAIL_MANAGEMENT,
                AppSubFeatures.SOCIAL_MANAGEMENT,
                AppSubFeatures.WEBCHAT_MANAGEMENT,
                AppSubFeatures.WEBFORM_MANAGEMENT,
                AppSubFeatures.MESSAGING_MANAGEMENT,
            ],
        },
        {
            title: 'Contact flow phrases',
            path: '/admin-settings/contact-flow-phrases',
            name: AppFeatures.CONTACT_FLOW_PHRASES,
        },
        {
            title: 'Data retention',
            path: '/admin-settings/data-retention',
            name: AppFeatures.DATA_RETENTION,
            subfeatures: [AppSubFeatures.DELETE_CUSTOMER_DATA],
        },
    ]
    adminSettingLinks.push(...getPluginNavLinks('adminSettings'))

    const QALinks: LinkProps[] = [
        {
            title: 'My scores',
            path: '/qa/myscores',
            name: AppFeatures.QA,
            notificationCount: unreadScoresCount,
        },
        {
            title: 'Evaluations',
            path: '/qa/scores',
            name: AppFeatures.QA,
            subfeatures: [AppSubFeatures.QA_CREATE, AppSubFeatures.QA_READ, AppSubFeatures.QA_EDIT],
        },
        {
            title: 'Calibration',
            path: '/qa/calibrations',
            name: AppFeatures.QA,
            subfeatures: [AppSubFeatures.QA_CREATE, AppSubFeatures.QA_READ, AppSubFeatures.QA_EDIT],
        },
        {
            title: 'Reports',
            path: '/qa/reports',
            name: AppFeatures.QA,
            subfeatures: [AppSubFeatures.QA_REPORTING, AppSubFeatures.QA_SELF_REPORTING],
        },
        {
            title: 'Templates',
            path: '/qa/templates',
            name: AppFeatures.QA,
            subfeatures: [AppSubFeatures.QA_TEMPLATES],
        },
    ]
    QALinks.push(...getPluginNavLinks('qa'))

    const TaskLinks = [
        {
            title: 'Task',
            path: '/task',
            name: AppFeatures.TASK_HISTORY,
        },
        {
            title: 'Outbound Email',
            path: '/task/outbound-email',
            name: AppFeatures.OUTBOUND_EMAIL,
        },
        ...getPluginNavLinks('task'),
    ]

    const ChatLinks = [
        {
            title: 'Chat',
            path: '/chat',
            name: AppFeatures.CHAT,
        },
        {
            title: 'Outbound SMS',
            path: '/chat/outbound-sms',
            name: AppFeatures.OUTBOUND_SMS,
        },
    ]

    const pathEq = (pn: string) => location.pathname === pn && !agentsActive
    const pathIncludes = (pn: string) => location.pathname.includes(pn) && !agentsActive

    const { focusElementRef: skipToContentRef, onFocusElement: skipToContent } =
        useFocusElement<HTMLAnchorElement>()

    return (
        <nav className="nav">
            <div className="skip-link-container">
                <a
                    className="skip-link"
                    href="#skip-to-content"
                    ref={skipToContentRef}
                    onKeyDown={(e) => {
                        if (e.key === ' ' || e.key === 'Spacebar') {
                            e.preventDefault()
                            const target = e.target as HTMLAnchorElement
                            target.click()
                        }
                    }}
                >
                    Skip to content
                </a>
            </div>

            {features.length > 1 && (
                <NavElement
                    onClick={agentsActive ? toggleAgents : undefined}
                    path="/"
                    selected={pathEq('/')}
                    icon={
                        <PhoneIcon className={`nav-voice ${pathEq('/') ? 'icon-selected' : ''}`} />
                    }
                    title="Dialler"
                    onNavigate={skipToContent}
                />
            )}

            {hasFeature(AppFeatures.CHAT) && (
                <NavElement
                    selected={pathIncludes('/chat')}
                    icon={
                        <img
                            src={pathIncludes('/chat') ? ChatOutline : ChatOutline}
                            alt="Chat"
                            title="Chat"
                        />
                    }
                    title="Chat"
                    links={ChatLinks}
                    onNavigate={skipToContent}
                />
            )}

            {hasFeature(AppFeatures.TASK_HISTORY) && (
                <NavElement
                    selected={pathIncludes('/task')}
                    icon={
                        <img
                            src={pathIncludes('/task') ? TaskMessageFill : TaskMessageOutline}
                            alt="Task"
                            title="Task"
                        />
                    }
                    title="Task"
                    links={TaskLinks}
                    onNavigate={skipToContent}
                />
            )}

            {hasFeature(AppFeatures.CASE) && (
                <NavElement
                    path="/case"
                    selected={pathIncludes('/case')}
                    icon={
                        <img
                            src={pathIncludes('/case') ? EmailFill : EmailOutline}
                            alt="Email"
                            title="Email"
                        />
                    }
                    title="Email"
                    links={getPluginNavLinks('case')}
                    onNavigate={skipToContent}
                />
            )}
            {hasFeature(AppFeatures.CAMPAIGN_MANAGEMENT) && (
                <NavElement
                    path="/campaignmanagement"
                    selected={pathIncludes('/campaignmanagement')}
                    icon={
                        <img
                            src={
                                pathIncludes('/campaignmanagement')
                                    ? CampaignManagementFill
                                    : CampaignManagementOutline
                            }
                            alt="Campaign Management"
                            title="Campaign Management"
                        />
                    }
                    title="Campaigns"
                    onNavigate={skipToContent}
                />
            )}

            <NavElement
                selected={pathIncludes('/metrics')}
                icon={
                    <img
                        src={pathIncludes('/metrics') ? MetricsFill : MetricsOutline}
                        alt="Metrics"
                        title="Metrics"
                    />
                }
                title="Metrics &amp; Data"
                links={metricsLinks}
                onNavigate={skipToContent}
            />
            <NavElement
                selected={pathIncludes('/qa')}
                icon={<img src={pathIncludes('/qa') ? QAFill : QAOutline} alt="QA" />}
                title="Quality Assurance"
                links={QALinks}
                formatTitle={(title, count) => {
                    if (count < 1) return title
                    return `${title}, ${count} new ${conditionalPluralise('evaluation', count)}`
                }}
                onNavigate={skipToContent}
            />
            <NavElement
                selected={pathIncludes('/admin-settings')}
                icon={
                    <img
                        src={
                            pathIncludes('/admin-settings')
                                ? AdminSettingsFill
                                : AdminSettingsOutline
                        }
                        alt="Admin Settings"
                    />
                }
                title="Admin Settings"
                links={adminSettingLinks}
                onNavigate={skipToContent}
            />
            <NavElement
                selected={pathIncludes('/settings')}
                icon={
                    <img
                        src={pathIncludes('/settings') ? SettingsFill : SettingsOutline}
                        alt="Settings"
                    />
                }
                title="Settings"
                links={settingLinks}
                onNavigate={skipToContent}
            />
        </nav>
    )
}

export default Nav
